import moment from 'moment';

export const CHANGE_DATE = 'CHANGE_DATE';
export const changeDate = (date) => {
  return { type: CHANGE_DATE, date: moment(date) };
}

export const SET_USER_ID = 'SET_USER_ID';
export const setUserId = (uid) => {
  return { type: SET_USER_ID, uid };
}
