import React, { useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import SurveyForm from '../components/SurveyForm';
import { useSelector } from 'react-redux';
import { useWebNavigateBack } from '../hooks/use-web-navigate-back';

const SurveyScreen = props => {
  useWebNavigateBack({
    navigation: props.navigation, routeTitle: 'Ankieta', routePath: '/ankieta'
  });

  const currentDate = useSelector(state => state.main.currentDate);

  useEffect(() => {
    props.navigation.setParams({ currentDate: currentDate });
  }, [currentDate]);

  return (
    <View style={styles.screen}>
      <SurveyForm
        currentDaySurveyData={props.navigation.getParam('currentDaySurveyData')}
        onSubmitSurvey={(surveyData) => {
          props.navigation.getParam('onSubmitSurvey')(surveyData);
          props.navigation.goBack();
        }}
      />
    </View>
  );
};

SurveyScreen.navigationOptions = (navigationData) => {
  let date = navigationData.navigation.getParam('currentDate');
  date = date ? date.format('DD.MM.YYYY') : '';
  return { headerTitle: `Ankieta (${date})` };
}

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    alignItems: 'center'
  }
});

export default SurveyScreen;
