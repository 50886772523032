import { LOAD_REPORTS, SET_CURRENT_DAY_REPORT, UPDATE_GLASS_COUNT, ADD_REPORT, ADD_STOOL, SUBMIT_SURVEY } from '../actions/daily-reports';

const initialState = {
  reports: []
}

const dailyReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_REPORT:
      const newReports = [...state.reports];
      newReports.push(action.report);
      return { ...state, reports: newReports };
    case SET_CURRENT_DAY_REPORT:
      return { ...state, currentDayReport: action.report };
    case UPDATE_GLASS_COUNT:
    case ADD_STOOL:
    case SUBMIT_SURVEY:
      const reportIndex = state.reports.findIndex((r) => {
        return r === action.report;
      });

      const updatedReports = [...state.reports];
      updatedReports[reportIndex] = action.updatedReport;

      return { ...state, reports: updatedReports, currentDayReport: action.updatedReport };
    case LOAD_REPORTS:
      return { ...state, reports: action.reports };
    default:
      return state;
  }
}

export default dailyReportsReducer;
