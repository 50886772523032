import React, { useCallback } from 'react';
import {
  TextArea,
  Button,
  FormControl,
  Input,
  Radio,
  Text,
} from 'native-base';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

const SurveyForm = props => {
  const [formData, setData] = React.useState(props.currentDaySurveyData || {});
  const [errors, setErrors] = React.useState({});

  const validateValuePresent = useCallback((key) => {
    if (formData[key] === undefined) {
      return { key: key, error: 'missing' }
    }

    return null;
  });

  const validateCorrectNumber = useCallback((key) => {
    const value = Number(formData[key]);

    if (isNaN(value) || value < 0) {
      return { key: key, error: 'invalid' }
    }

    return null;
  });

  const validate = useCallback(() => {
    const results = [
      validateValuePresent('hardStools'),
      validateValuePresent('stomachPain'),
      validateValuePresent('stomachBloating'),
      validateValuePresent('stoolPain'),
      validateValuePresent('stoolOnUnderwear'),
      validateValuePresent('retentionBehaviors'),
      validateValuePresent('macrogolsDose') || validateCorrectNumber('macrogolsDose')
    ];

    const newErrors = {};

    results.forEach(x => {
      if (x) {
        newErrors[x.key] = x.error
      }
    });

    if (Object.keys(newErrors).length) {
      setErrors(newErrors);
      return false;
    }

    setErrors({});
    return true;
  });

  const removeError = useCallback(keyToDelete => {
    const keys = Object.keys(errors);
    if (!keys.includes(keyToDelete)) return;

    const newErrors = {};
    keys.forEach(k => {
      if (k !== keyToDelete) {
        newErrors[k] = errors[k]
      }
    });

    setErrors(newErrors);
  });

  const onSubmit = useCallback(() => {
    if (!validate()) {
      console.log('Validation Failed', errors);
      return;
    }

    props.onSubmitSurvey(formData);
  });

  const setFormValue = useCallback((key, value) => {
    setData({ ...formData, [key]: value });
    removeError(key);
  });

  const getMacrogolsError = useCallback(() => {
    if (errors.macrogolsDose === 'missing') { return 'Proszę wpisać wartość'; }
    if (errors.macrogolsDose === 'invalid') { return 'Wartość musi być liczbą'; }
  });

  return (
    <KeyboardAwareScrollView width="100%" style={{ paddingHorizontal: "5%" }}>
      <FormControl mt={3} isRequired isInvalid={'hardStools' in errors}>
        <FormControl.Label _text={{ bold: true }}>Stolce o dużej objętości?</FormControl.Label>
        <FormControl.HelperText my={0}>
          Stolce zatykające toaletę, trudne do spłukania, wylewające się z pieluchy
        </FormControl.HelperText>
        <Radio.Group
          name="hardStools"
          defaultValue={formData.hardStools}
          mt={2}
          style={{ flexDirection: "row" }}
          onChange={(value) => setFormValue('hardStools', value)}
          accessibilityLabel="Wybierz jedną z opcji"
        >
          <Radio value="yes" my={1} mr={2}>
            <Text ml={2}>Tak</Text>
          </Radio>
          <Radio value="no" my={1} mr={2}>
            <Text ml={2}>Nie</Text>
          </Radio>
          <Radio value="none" my={1}>
            <Text ml={2}>Brak stolca</Text>
          </Radio>
        </Radio.Group>
        <FormControl.ErrorMessage>Proszę wybrać jedną z opcji</FormControl.ErrorMessage>
      </FormControl>

      <FormControl mt={3} isRequired isInvalid={'stomachPain' in errors}>
        <FormControl.Label mb={1} _text={{ bold: true }}>Ból brzucha?</FormControl.Label>
        <Radio.Group
          name="stomachPain"
          defaultValue={formData.stomachPain}
          mt={0}
          style={{ flexDirection: "row" }}
          onChange={(value) => setFormValue('stomachPain', value)}
          accessibilityLabel="Wybierz jedną z opcji"
        >
          <Radio value="yes" my={1} mr={2}>
            <Text ml={2}>Tak</Text>
          </Radio>
          <Radio value="no" my={1} mr={2}>
            <Text ml={2}>Nie</Text>
          </Radio>
        </Radio.Group>
        <FormControl.ErrorMessage>Proszę wybrać jedną z opcji</FormControl.ErrorMessage>
      </FormControl>

      <FormControl mt={3} isRequired isInvalid={'stomachBloating' in errors}>
        <FormControl.Label mb={1} _text={{ bold: true }}>Wzdęcia brzucha?</FormControl.Label>
        <Radio.Group
          name="stomachBloating"
          defaultValue={formData.stomachBloating}
          mt={0}
          style={{ flexDirection: "row" }}
          onChange={(value) => setFormValue('stomachBloating', value)}
          accessibilityLabel="Wybierz jedną z opcji"
        >
          <Radio value="yes" my={1} mr={2}>
            <Text ml={2}>Tak</Text>
          </Radio>
          <Radio value="no" my={1} mr={2}>
            <Text ml={2}>Nie</Text>
          </Radio>
        </Radio.Group>
        <FormControl.ErrorMessage>Proszę wybrać jedną z opcji</FormControl.ErrorMessage>
      </FormControl>

      <FormControl mt={3} isRequired isInvalid={'stoolPain' in errors}>
        <FormControl.Label mb={1} _text={{ bold: true }}>Ból przy oddawaniu stolca?</FormControl.Label>
        <Radio.Group
          name="stoolPain"
          defaultValue={formData.stoolPain}
          mt={0}
          style={{ flexDirection: "row" }}
          onChange={(value) => setFormValue('stoolPain', value)}
          accessibilityLabel="Wybierz jedną z opcji"
        >
          <Radio value="yes" my={1} mr={2}>
            <Text ml={2}>Tak</Text>
          </Radio>
          <Radio value="no" my={1} mr={2}>
            <Text ml={2}>Nie</Text>
          </Radio>
          <Radio value="none" my={1}>
            <Text ml={2}>Brak stolca</Text>
          </Radio>
        </Radio.Group>
        <FormControl.ErrorMessage>Proszę wybrać jedną z opcji</FormControl.ErrorMessage>
      </FormControl>

      <FormControl mt={3} isRequired isInvalid={'stoolOnUnderwear' in errors}>
        <FormControl.Label mb={1} _text={{ bold: true }}>Brudzenie bielizny kałem?</FormControl.Label>
        <Radio.Group
          name="stoolOnUnderwear"
          defaultValue={formData.stoolOnUnderwear}
          mt={0}
          style={{ flexDirection: "row" }}
          onChange={(value) => setFormValue('stoolOnUnderwear', value)}
          accessibilityLabel="Wybierz jedną z opcji"
        >
          <Radio value="yes" my={1} mr={2}>
            <Text ml={2}>Tak</Text>
          </Radio>
          <Radio value="no" my={1} mr={2}>
            <Text ml={2}>Nie</Text>
          </Radio>
        </Radio.Group>
        <FormControl.ErrorMessage>Proszę wybrać jedną z opcji</FormControl.ErrorMessage>
      </FormControl>

      <FormControl mt={3} isRequired isInvalid={'retentionBehaviors' in errors}>
        <FormControl.Label _text={{ bold: true }}>Zachowania retencyjne?</FormControl.Label>
        <FormControl.HelperText my={0}>
          Przyjmowanie pozycji ciała sprzyjającej wstrzymywaniu wypróżnienia lub nasilone świadome wstrzymywanie wypróżnienia np. krzyżowanie nóg, zaciskanie pośladków
        </FormControl.HelperText>
        <Radio.Group
          name="retentionBehaviors"
          defaultValue={formData.retentionBehaviors}
          mt={2}
          style={{ flexDirection: "row" }}
          onChange={(value) => setFormValue('retentionBehaviors', value)}
          accessibilityLabel="Wybierz jedną z opcji"
        >
          <Radio value="yes" my={1} mr={2}>
            <Text ml={2}>Tak</Text>
          </Radio>
          <Radio value="no" my={1} mr={2}>
            <Text ml={2}>Nie</Text>
          </Radio>
        </Radio.Group>
        <FormControl.ErrorMessage>Proszę wybrać jedną z opcji</FormControl.ErrorMessage>
      </FormControl>

      <FormControl mt={3} isInvalid={'otherSymptoms' in errors}>
        <FormControl.Label _text={{ bold: true }}>Inne objawy</FormControl.Label>
        <TextArea
          name="otherSymptoms"
          value={formData.otherSymptoms || ''}
          onChangeText={(v) => setFormValue('otherSymptoms', v)}
          h={20}
          placeholder="Tu wpisz pozostałe objawy... " />
      </FormControl>

      <FormControl isRequired isInvalid={'macrogolsDose' in errors}>
        <FormControl.Label _text={{ bold: true }}>Dawka makrogoli (gramy)</FormControl.Label>
        <Input
          value={formData.macrogolsDose || ''}
          onChangeText={(v) => setFormValue('macrogolsDose', v.replace(',', '.'))}
          keyboardType='numeric'
        />
        <FormControl.ErrorMessage>
          {getMacrogolsError()}
        </FormControl.ErrorMessage>
      </FormControl>

      <FormControl mt={3} isInvalid={'otherMedicine' in errors}>
        <FormControl.Label _text={{ bold: true }}>Inne leki</FormControl.Label>
        <TextArea
          name="otherMedicine"
          value={formData.otherMedicine || ''}
          onChangeText={(v) => setFormValue('otherMedicine', v)}
          h={20}
          placeholder="Podaj inne podawane leki... " />
      </FormControl>

      <Button onPress={onSubmit} my={5} colorScheme="primary">
        Zapisz
      </Button>
    </KeyboardAwareScrollView>
  );
}

export default SurveyForm;
