import React from 'react';
import { Box, Heading, useBreakpointValue } from 'native-base';
import { TouchableHighlight } from 'react-native';
import { withNavigation } from 'react-navigation';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import Colors from '../theme/Colors';

const SurveyTile = ({ currentDaySurveyData, onSubmitSurvey, navigation }) => {
  const tileSize = useBreakpointValue({
    base: 150,
    sm: 200,
    md: 250,
    lg: 300,
    xl: 350
  });

  const iconSize = useBreakpointValue({
    base: 64,
    sm: 78,
    lg: 128
  });

  return (
    <TouchableHighlight
      onPress={() => navigation.navigate({
        routeName: 'Survey',
        params: {
          currentDaySurveyData: currentDaySurveyData,
          onSubmitSurvey: onSubmitSurvey
        }
      })}>
      <Box p={2} rounded='md' bg={'primary.500'} style={{ width: tileSize, height: tileSize }}>
        <Heading size={{ base: 'md', sm: 'lg', md: 'lg', lg: 'lg', xl: 'xl' }} color={'white'}>Ankieta</Heading>
        <Box style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
          <MaterialCommunityIcons name={currentDaySurveyData ? 'check-decagram' : 'comment-account-outline'} color={'white'} size={iconSize}/> :
        </Box>
      </Box>
    </TouchableHighlight>
  );
};

export default withNavigation(SurveyTile);
