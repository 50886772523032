import { useCallback, useEffect } from 'react';
import { Platform } from 'react-native';

export function useWebNavigateBack({ navigation, routeTitle, routePath }) {
  if (Platform.OS !== 'web') {
    return;
  }

  useEffect(() => {
    addWebBackListener();

    return removeWebBackListener;
  }, []);

  const popStateHandler = useCallback(() => {
    navigation.goBack();
  });

  const addWebBackListener = useCallback(() => {
    window.history.pushState({}, `Dzienniczek Wypróżnień - ${routeTitle}`, routePath);

    window.addEventListener('popstate', popStateHandler);
  });

  const removeWebBackListener = useCallback(() => {
    window.removeEventListener('popstate', popStateHandler);

    window.history.replaceState({}, '', '/');
  });
}
