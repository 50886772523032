import React from 'react';
import { Box, Heading, useBreakpointValue, Text } from 'native-base';

import Colors from '../theme/Colors';
import { TouchableHighlight } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { marginTop } from 'styled-system';

const WaterTile = props => {
  const tileSize = useBreakpointValue({
    base: 150,
    sm: 200,
    md: 250,
    lg: 300,
    xl: 350
  });

  const counterSize = useBreakpointValue({
    base: 64,
    sm: 78,
    lg: 128
  });

  const iconSize = useBreakpointValue({
    base: 52,
    sm: 60,
    lg: 110
  });

  function incrementGlasses() {
    if (props.glassCount < 99) {
      props.setGlassCount(props.glassCount + 1);
    }
  }

  return (
    <TouchableHighlight
      onPress={incrementGlasses}
      onLongPress={() => props.setGlassCount(0)}>
      <Box p={2} rounded='md' bg={'primary.500'} style={{ width: tileSize, height: tileSize }}>
        <Heading size={{ base: 'md', sm: 'lg', md: 'lg', lg: 'lg', xl: 'xl' }} color='white'>Wypita Woda</Heading>
        <Box style={{ justifyContent: 'center', alignItems: 'center', flex: 1, flexDirection: 'row' }}>
          <Text fontWeight='bold' fontSize={counterSize} color='white'>{props.glassCount}</Text>
          <MaterialCommunityIcons style={{marginTop: 4}} name='cup-water' color={'#27a0e5'} size={iconSize}/>
        </Box>
      </Box>
    </TouchableHighlight>
  );
};

export default WaterTile;
