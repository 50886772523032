
import { LocaleConfig } from 'react-native-calendars';

const configPolishLocaleForReactCalendars = () => {
  LocaleConfig.locales['pl'] = {
    monthNames: ['Styczeń','Luty','Marzec','Kwiecień','Maj','Czerwiec','Lipiec','Sierpień','Wrzesień','Październik','Listopad','Grudzień'],
    monthNamesShort: ['Sty','Lut','Mar','Kwi','Maj','Cze','Lip','Sie','Wrz','Paź','Lis','Gru'],
    dayNames: ['Niedziela','Poniedziałek','Wtorek','Środa','Czwartek','Piątek','Sobota'],
    dayNamesShort: ['Nd','Pon','Wt','Śr','Cz','Pt','So'],
    today: 'Dzisiaj'
  };
  LocaleConfig.defaultLocale = 'pl';
}

export { configPolishLocaleForReactCalendars };
