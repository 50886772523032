class DailyReport {
  isPersisted() {
    return this.id !== null;
  }

  addStool(bristolType) {
    this.stools.push({ bristolType, addedAt: new Date() })
  }

  firebasePayload() {
    return {
      date: this.date,
      userId: this.userId,
      stools: this.stools,
      glassCount: this.glassCount,
      surveyResponse: this.surveyResponse,
    }
  }

  static emptyForDate(date, userId) {
    const report = new DailyReport();

    report.id = null;
    report.date = date;
    report.userId = userId;
    report.stools = [];
    report.glassCount = 0;
    report.surveyResponse = null;

    return report;
  }

  static clone(report) {
    const newReport = new DailyReport();

    newReport.id = report.id;
    newReport.date = report.date;
    newReport.userId = report.userId;
    newReport.stools = [...report.stools];
    newReport.glassCount = report.glassCount;
    newReport.surveyResponse = report.surveyResponse ? { ...report.surveyResponse } : null;

    return newReport;
  }

  static fromFirebaseReport(firebaseReport) {
    const report = new DailyReport();
    const data = firebaseReport.data();

    report.id = firebaseReport.id;
    report.date = data.date.toDate();
    report.userId = data.userId;
    report.stools = data.stools.map((s) => ({ ...s, addedAt: s.addedAt.toDate() }));
    report.glassCount = data.glassCount;
    report.surveyResponse = data.surveyResponse;

    return report;
  }
}

export default DailyReport;
