import firebase from "firebase/app"
import "firebase/firestore"

const initFirebase = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyAIsZ9gzS4YKNKQMX19p_LdjguBmMJKlkE",
    authDomain: "phdproject-e0a91.firebaseapp.com",
    projectId: "phdproject-e0a91",
    storageBucket: "phdproject-e0a91.appspot.com",
    messagingSenderId: "468991110132",
    appId: "1:468991110132:web:c313faeee9c66d6b0ee7a1"
  };

  firebase.initializeApp(firebaseConfig);
  firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
}

export { firebase, initFirebase };
