import { Platform } from 'react-native';
import * as Notifications from 'expo-notifications';
import Constants from 'expo-constants';

const DAILY_REMINDER_ID = 'DAILY_REMINDERS';
const DAILY_REMINDER_HOUR = 20;

const BROWSER_NOT_SUPPORTED_ERROR = "Powiadomenia nie są dostępne w wersji przeglądarkowej";
const DEVICE_NOT_SUPPORTED_ERROR = "Powiadomenia nie są dostępne na tym urządzeniu";
const FAILED_TO_ENABLE_ERROR = "Nie udało się włączyć powiadomień. Sprawdź ustawienia systemowe dla aplikacji 'Dzienniczek Wypróżnień' i spróbuj ponownie.";

class DailyNotificationsService {
  static notificationsSupported() {
    return (Platform.OS !== 'web' && Constants.isDevice);
  }

  static async loadNotification() {
    if (!this.notificationsSupported()) { return; }

    const notifications = await Notifications.getAllScheduledNotificationsAsync();

    return notifications.find(n => n.identifier === DAILY_REMINDER_ID);
  }

  static async enableNotification() {
    const registerResult = await this.registerForPushNotificationsAsync();
    if (!registerResult.success) { return registerResult; }

    try {
      await this.scheduleReminders();
      return { success: true }
    } catch(e) {
      return { success: false, error: FAILED_TO_ENABLE_ERROR }
    }
  }

  static async disableNotification() {
    return await Notifications.cancelScheduledNotificationAsync(DAILY_REMINDER_ID);
  }

  static async registerForPushNotificationsAsync() {
    if (Platform.OS === 'web') {
      return { success: false, error: BROWSER_NOT_SUPPORTED_ERROR };
    }

    if (!Constants.isDevice) {
      return { success: false, error: DEVICE_NOT_SUPPORTED_ERROR };
    }

    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      return { success: false, error: FAILED_TO_ENABLE_ERROR }
    }

    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('daily_notifications', {
        name: 'Codziennie przypomnienia'
      });
    }

    return { success: true };
  }

  static async scheduleReminders() {
    return await Notifications.scheduleNotificationAsync({
      identifier: DAILY_REMINDER_ID,
      content: {
        title: "Przypomnienie o badaniu",
        body: 'Upewnij się, czy wypełniłeś(aś) dzisiejszą ankietę. Dziękujemy!'
      },
      trigger: { hour: DAILY_REMINDER_HOUR, minute: 0, repeats: true },
    });
  };
}

export default DailyNotificationsService;
