import { createAppContainer, createSwitchNavigator } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';

import AuthScreen from '../screens/AuthScreen';
import AddStoolScreen from '../screens/AddStoolScreen';
import HelpScreen from '../screens/HelpScreen';
import HomeScreen from '../screens/HomeScreen';
import SettingsScreen from '../screens/SettingsScreen';
import SurveyScreen from '../screens/SurveyScreen';
import AppLoadingScreen from '../screens/AppLoadingScreen';
import PrivacyPolicyScreen from '../screens/PrivacyPolicyScreen';

import Colors from '../theme/Colors';

const ReportsNavigator = createStackNavigator({
    Home: HomeScreen,
    Survey: SurveyScreen,
    AddStool: AddStoolScreen,
    Help: HelpScreen,
    Settings: SettingsScreen
  },
  {
    defaultNavigationOptions: {
      headerStyle: {
        backgroundColor: Colors.headerBackgroundColor
      },
      headerTintColor: Colors.headerTintColor
  }
});

const MainNavigator = createSwitchNavigator({
  AppLoading: AppLoadingScreen,
  Auth: AuthScreen,
  Help: HelpScreen,
  PrivacyPolicy: PrivacyPolicyScreen,
  Reports: ReportsNavigator
}, { initialRouteName: 'AppLoading' })

export default createAppContainer(MainNavigator);
