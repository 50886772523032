import React, { useCallback } from 'react';
import { Text, Linking } from 'react-native';
import { Box, Heading } from 'native-base';
import { useWebNavigateBack } from '../hooks/use-web-navigate-back';
import EmailLink from '../components/EmailLink';

const HelpScreen = props => {
  useWebNavigateBack({
    navigation: props.navigation, routeTitle: 'Pomoc', routePath: '/pomoc'
  });

  return (
    <Box p="4">
      <Heading size="lg" mb={2}>
        Pomoc
      </Heading>

      <Text mb={2}>
        W przypadku pytań dotyczących badania lub aplikacji prosimy o kontakt mailowy na adres <EmailLink />
      </Text>
  </Box>
  );
};

HelpScreen.navigationOptions = {
  headerTitle: 'Pomoc'
}

export default HelpScreen;
