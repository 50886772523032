import firebase from "firebase/app"
import DailyReport from "../../models/DailyReport";

export const SET_CURRENT_DAY_REPORT = 'SET_CURRENT_DAY_REPORT';
export const setCurrentDayReport = (report) => {
  return { type: SET_CURRENT_DAY_REPORT, report };
}

export const ADD_REPORT = 'ADD_REPORT';
export const addReport = (report) => {
  return { type: ADD_REPORT, report };
}

export const ADD_STOOL = 'ADD_STOOL';
export const addStool = (report, bristolType) => {
  return async dispatch => {
    let updatedReport = DailyReport.clone(report);
    updatedReport.addStool(bristolType);

    await updateReport(updatedReport, { stools: updatedReport.stools });

    dispatch({ type: ADD_STOOL, report, updatedReport });
  };
}

export const UPDATE_GLASS_COUNT = 'UPDATE_GLASS_COUNT';
export const updateGlassCount = (report, glassCount) => {
  return async dispatch => {
    let updatedReport = DailyReport.clone(report);
    updatedReport.glassCount = glassCount;

    await updateReport(updatedReport, { glassCount });

    dispatch({ type: UPDATE_GLASS_COUNT, report, updatedReport });
  }
}

export const SUBMIT_SURVEY = 'SUBMIT_SURVEY';
export const submitSurvey = (report, surveyResponse) => {
  return async dispatch => {
    let updatedReport = DailyReport.clone(report);
    updatedReport.surveyResponse = surveyResponse;

    await updateReport(updatedReport, { surveyResponse: updatedReport.surveyResponse });

    dispatch({ type: SUBMIT_SURVEY, report, updatedReport });
  }
}

export const LOAD_REPORTS = 'LOAD_REPORTS';
export const loadReports = (userId) => {
  return async dispatch => {
    const querySnapshot = await firebase.firestore().collection("daily_reports").where("userId", "==", userId).get();
    const reports = [];
    querySnapshot.forEach((doc) => {
      reports.push(DailyReport.fromFirebaseReport(doc));
    });

    dispatch({ type: LOAD_REPORTS, reports });
  };
}

const updateReport = async (updatedReport, updateParams) => {
  let firebaseReportRef = null;
  if (updatedReport.isPersisted()) {
    firebaseReportRef = firebase.firestore().collection("daily_reports").doc(updatedReport.id);
    await firebaseReportRef.update(updateParams);
  } else {
    firebaseReportRef = await firebase.firestore().collection("daily_reports").add(updatedReport.firebasePayload());
    updatedReport.id = firebaseReportRef.id;
  }
}
