import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { NativeBaseProvider, extendTheme } from 'native-base';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import ReduxThunk from 'redux-thunk';

import mainReducer from './store/reducers/main';
import dailyReportsReducer from './store/reducers/daily-reports';

import MainNavigator from './navigation/MainNavigator';

import { configPolishLocaleForReactCalendars } from './initialization/react-calendars';

configPolishLocaleForReactCalendars();

const rootReducer = combineReducers({ main: mainReducer, dailyReports: dailyReportsReducer });
const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

export default function App() {
  const theme = extendTheme({
    colors: {
      primary: {
        50: '#f2e3ff',
        100: '#d3b2ff',
        200: '#b47fff',
        300: '#964dff',
        400: '#781aff',
        500: '#5f00e6',
        600: '#4a00b4',
        700: '#350082',
        800: '#200050',
        900: '#0d0020',
      }
    }
  });

  return (
    <Provider store={store}>
      <NativeBaseProvider theme={theme}>
        <MainNavigator />
      </NativeBaseProvider>
    </Provider>
  );
}
