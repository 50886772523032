import React, { useCallback } from 'react';
import { Linking } from 'react-native';
import { Link } from 'native-base';

export const EMAIL = "aleksandra.bajena@uckwum.pl"

const EmailLink = props => {
  const openEmail = useCallback(() => {
    Linking.openURL(`mailto:${EMAIL}`)
  });

  return <Link
    href={`mailto:${EMAIL}`}
    onPress={openEmail}
    isExternal
    _text={{
      color: "blue.400",
      fontSize: "sm"
    }}
    _android={{ ml: 0.25, mb: -0.5 }}
    _ios={{ ml: 0.25, mb: -0.5 }}
  >
    {EMAIL}
  </Link>;
};

export default EmailLink;
