import moment from 'moment';
import { CHANGE_DATE, SET_USER_ID } from '../actions/main';

const initialState = {
  currentDate: moment(),
  userId: ''
}

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_DATE:
      return { ...state, currentDate: action.date };
      case SET_USER_ID:
        return { ...state, userId: action.uid };
    default:
      return state;
  }
}

export default mainReducer;
