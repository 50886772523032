import React, { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Heading,
  VStack,
  FormControl,
  Input,
  Link,
  Button,
  Modal,
  Text
} from 'native-base';

import firebase from "firebase/app"
import "firebase/auth"
import { setUserId } from '../store/actions/main';
import { KeyboardAvoidingView } from 'native-base';
import { TouchableWithoutFeedback, Keyboard, Platform } from 'react-native';

import EmailLink from '../components/EmailLink';

const ERROR_MESSAGES = {
  'auth/invalid-email': 'Nieprawidłowy email',
  'auth/wrong-password': 'Nieprawidłowe hasło',
  'auth/user-not-found': 'Pacjent nie istnieje. Sprawdź czy podałeś poprawny email pacjenta.'
}

const AuthScreen = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isHelpModalVisible, setHelpModalVisible] = useState(false);

  const passwordRef = useRef();

  const dispatch = useDispatch();

  const navigateToReports = useCallback((uid) => {
    console.log('Logged in with userId', uid);

    dispatch(setUserId(uid));
    props.navigation.navigate('Reports');
  }, []);

  const signIn = useCallback(() => {
    setIsSubmitting(true);
    firebase.auth().signInWithEmailAndPassword(email, password)
    .then((userCredential) => {
      const user = userCredential.user;

      navigateToReports(user.uid);
    })
    .catch((error) => {
      console.error(error);
      var errorCode = error.code;
      var errorMessage = error.message;
      setIsSubmitting(false);
      setError(ERROR_MESSAGES[errorCode] || errorMessage)
    });
  }, [email, password]);

  return (
    <Box safeArea flex={1} p="2" py="8" w="90%" mx="auto">
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
      >
        <TouchableWithoutFeedback onPress={() => {
          if (Platform.OS === "web") { return; }

          Keyboard.dismiss();
        }}>
          <Box>
            {isHelpModalVisible && (
              <Modal
                isOpen={isHelpModalVisible}
                onClose={() => setHelpModalVisible(false)}
                avoidKeyboard
                bottom="4"
                size="lg"
              >
                <Modal.Content>
                  <Modal.CloseButton />
                  <Modal.Header>Problem z logowaniem?</Modal.Header>
                  <Modal.Body>
                    <Text mb={2}>
                      Email (w formacie xxxxx@dzienniczek-wyproznien.info) i hasło do logowania powinieneś otrzymać razem z instrukcjami do badania.
                    </Text>

                    <Text>
                      Jeśli nie możesz znaleźć tych informacji skontaktuj się z nami pod adresem <EmailLink />.
                    </Text>
                  </Modal.Body>
                </Modal.Content>
              </Modal>
            )}

            <Heading size="lg" fontWeight="600" color="coolGray.800">
              Witaj
            </Heading>
            <Heading mt="1" color="coolGray.600" fontWeight="medium" size="xs">
              Zaloguj się aby kontynuować...
            </Heading>

            <VStack space={3} mt="5">
              <FormControl>
                <FormControl.Label
                  _text={{
                    color: 'coolGray.800',
                    fontSize: 'xs',
                    fontWeight: 500,
                  }}>
                  Email Pacjenta
                </FormControl.Label>
                <Input
                  autoFocus={true}
                  returnKeyType="next"
                  keyboardType="email-address"
                  autoCapitalize="none"
                  required
                  email
                  onChangeText={setEmail}
                  value={email}
                  onSubmitEditing={() => {
                    if (Platform.OS === "web") {
                      signIn();
                      return;
                    }

                    passwordRef.current.focus();
                  }}
                  blurOnSubmit={false}
                />
              </FormControl>
              <FormControl>
                <FormControl.Label
                  _text={{
                    color: 'coolGray.800',
                    fontSize: 'xs',
                    fontWeight: 500,
                  }}>
                  Hasło
                </FormControl.Label>
                <Input
                  ref={passwordRef}
                  type="password"
                  returnKeyType="go"
                  keyboardType="default"
                  secureTextEntry
                  minLength={5}
                  onChangeText={setPassword}
                  value={password}
                  onSubmitEditing={signIn}
                />
                <Link
                  onPress={() => setHelpModalVisible(true)}
                  _text={{ fontSize: 'xs', fontWeight: '500', color: 'indigo.500' }}
                  alignSelf="flex-end"
                  mt="1">
                  Zapomniałeś hasła?
                </Link>
              </FormControl>
              <FormControl isInvalid={!!error}>
                <Button
                  mt="2" colorScheme="indigo" _text={{ color: 'white' }}
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                  onPress={() => signIn()}>
                  Zaloguj
                </Button>

                <FormControl.ErrorMessage>
                  {error}
                </FormControl.ErrorMessage>
              </FormControl>
            </VStack>
          </Box>
        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
    </Box>
  );
}

AuthScreen.navigationOptions = {
  headerTitle: 'Dzienniczek Wypróżnień'
}

export default AuthScreen;
