import React, { useCallback, useEffect, useState } from 'react';
import { Box, Switch, Text, Divider, HStack, VStack, Center, useToast } from 'native-base';
import { useWebNavigateBack } from '../hooks/use-web-navigate-back';
import DailyNotificationsService from '../services/DailyNotificationsService';

const SettingsScreen = props => {
  const toast = useToast();
  const [isNotificationEnabled, setIsNotificationEnabled] = useState(false);

  useWebNavigateBack({
    navigation: props.navigation, routeTitle: 'Ustawienia', routePath: '/ustawienia'
  });

  useEffect(() => {
    const loadNotifications = async () => {
      const notification = await DailyNotificationsService.loadNotification();
      setIsNotificationEnabled(!!notification);
    };
    loadNotifications();
  }, []);

  const showError = useCallback((error) => {
    toast.show({
      title: error,
      placement: "top",
    });
  })

  const onToggleNotifications = useCallback(async (enabled) => {
    if (enabled) {
      setIsNotificationEnabled(true);
      const { success, error } = await DailyNotificationsService.enableNotification();
      if (!success) {
        setIsNotificationEnabled(false);
        showError(error);
      }
    } else {
      setIsNotificationEnabled(false);
      await DailyNotificationsService.disableNotification();
    }

    console.log(await DailyNotificationsService.loadNotification());
  });

  return (
    <Box width="100%">
      <HStack p="4">
        <VStack flex={1}>
          <Text color="coolGray.800" bold>Przypomnienia o wypełnieniu danych</Text>
          <Text mt="1" color="coolGray.600" fontSize='xs'>Gdy ta opcja jest włączona aplikacja będzie wysyłać każdego dnia o godzinie 20 powiadomienia przypominające o wypełnieniu danych.</Text>
        </VStack>
        <Divider orientation="vertical" mx="4" />
        <Center>
          <Switch isChecked={isNotificationEnabled} onToggle={onToggleNotifications}/>
        </Center>
      </HStack>
      <Divider></Divider>
    </Box>
  );
};


SettingsScreen.navigationOptions = {
  headerTitle: 'Ustawienia'
}

export default SettingsScreen;
