import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button, Text, useToast } from 'native-base';
import { useSelector } from 'react-redux';
import DailyNotificationsService from '../services/DailyNotificationsService';

const WelcomeModal = () => {
  const toast = useToast();
  const [isVisible, setVisible] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const reports = useSelector(state => state.dailyReports.reports);

  useEffect(() => {
    const load = async () => {
      if (reports.length === 0) { return; }
      if (reports.find(r => r.isPersisted())) {
        setVisible(false);
        return;
      }

      if (!DailyNotificationsService.notificationsSupported() || await DailyNotificationsService.loadNotification()) {
        setVisible(false);
        return;
      }

      setVisible(true);
      setLoading(false);
    }

    load();
  }, [reports]);

  const showMessage = useCallback((error) => {
    toast.show({
      title: error,
      placement: "top",
    });
  })

  const enableNotifications = useCallback(async () => {
    const { success, error } = await DailyNotificationsService.enableNotification();
    if (!success) {
      showMessage(error);
      return;
    }

    console.log(await DailyNotificationsService.loadNotification());
    setVisible(false);
    showMessage('Powiadomienia włączone');
  });

  return (
    <Modal
      isOpen={!isLoading && isVisible}
      onClose={() => setVisible(false)}
      size="lg"
    >
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>Witaj!</Modal.Header>
        <Modal.Body>
          <Text mb={2}>
           Systematyczne uzupełnianie dzienniczka jest kluczowe dla badania.
          </Text>
          <Text mb={2}>
            Prosimy, włącz funkcję powiadomień, abyś nie zapomniał(a) wprowadzić aktualnych danych. Dziękujemy!
          </Text>
          <Text>
            Powiadomienia będą przychodzić codziennie o 20. Możesz je wyłączyć w ustawieniach.
          </Text>
        </Modal.Body>
        <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onPress={() => {
                  setVisible(false);
                }}
              >
                Zamknij
              </Button>
              <Button
                onPress={enableNotifications}
              >
                Włącz powiadomienia
              </Button>
            </Button.Group>
          </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default WelcomeModal;
