import React, { useCallback, useEffect } from 'react';
import { StyleSheet, ScrollView } from 'react-native';
import { View, Image, Text, useBreakpointValue, Box, Button, Divider, VStack, Modal } from 'native-base';
import { useSelector } from 'react-redux';
import { useWebNavigateBack } from '../hooks/use-web-navigate-back';

const stoolData = {
  [1]: {
    image: require('../assets/stool-types/1.png'),
    description: 'Pojedyncze twarde grudki, podobne do orzechów (trudne do wydalenia)'
  },
  [2]: {
    image: require('../assets/stool-types/2.png'),
    description: 'Stolec o kształcie wydłużonym, grudkowaty'
  },
  [3]: {
    image: require('../assets/stool-types/3.png'),
    description: 'Stolec o kształcie wydłużonym, z pęknięciami na powierzchni'
  },
  [4]: {
    image: require('../assets/stool-types/4.png'),
    description: 'Stolec o kształcie wydłużonym lub wężowatym, gładki i miękki'
  },
  [5]: {
    image: require('../assets/stool-types/5.png'),
    description: 'Miękkie, małe grudki o wyraźnych brzegach (łatwe do wypróżnienia)'
  },
  [6]: {
    image: require('../assets/stool-types/6.png'),
    description: 'Małe elementy o postrzępionych brzegach (kłaczki), papkowaty stolec'
  },
  [7]: {
    image: require('../assets/stool-types/7.png'),
    description: 'Wodnisty, całkowicie płynny bez grudek'
  },
}

const AddStoolScreen = props => {
  useWebNavigateBack({
    navigation: props.navigation, routeTitle: 'Oddane Stolce', routePath: '/oddane-stolce'
  });

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [modalStoolType, setModalStoolType] = React.useState(null);
  const currentDate = useSelector(state => state.main.currentDate);

  useEffect(() => {
    props.navigation.setParams({ currentDate: currentDate });
  }, [currentDate]);

  const imageSize = useBreakpointValue({
    base: 70,
    sm: 90,
    lg: 90
  });

  const renderStool = (type) => {
    return (
      <View>
        <Box py={2} px={2} style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View borderColor={'primary.500'} borderWidth={2} style={{borderRadius: imageSize, overflow: 'hidden'}}>
            <Image
              w={`${imageSize}px`}
              h={`${imageSize}px`}
              alt={`Typ ${type}`}
              resizeMode='contain'
              source={stoolData[type].image}
              style={{backgroundColor: 'white' }}
            />
          </View>

          <VStack paddingLeft={'15px'} paddingRight={'15px'} style={{ flex: 1 }}>
            <Text color="coolGray.800" bold>{`Typ ${type}`}</Text>
            <Text color="coolGray.600" fontSize='xs'>{stoolData[type].description}</Text>
          </VStack>

          <Button colorScheme={'primary'} size={'sm'} onPress={() => {
            setModalStoolType(type);
            setIsModalVisible(true);
          }}>Wybierz</Button>
        </Box>

        <Divider></Divider>
      </View>
    );
  };

  const saveStool = useCallback((type) => {
    props.navigation.getParam('addStool')(type);
    props.navigation.goBack();
  });

  return (
    <View style={styles.screen}>
      {isModalVisible && (
        <Modal
          isOpen={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          avoidKeyboard
          bottom="4"
          size="lg"
        >
          <Modal.Content>
            <Modal.CloseButton />
            <Modal.Header>Na pewno?</Modal.Header>
            <Modal.Body>
              <Text mb={2}>
                Wybrano stolec typu <Text bold>{modalStoolType}</Text>:
              </Text>
              <Text italic mb={2}>{stoolData[modalStoolType].description}</Text>

              <Text>
                Jeśli wybór jest poprawny kliknij przycisk "Zapisz":
              </Text>
            </Modal.Body>
            <Modal.Footer>
              <Button
                flex={1}
                mb={4}
                mr={3}
                onPress={() => {
                  saveStool(modalStoolType);
                  setIsModalVisible(false);
                }}
              >
                Zapisz
              </Button>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      )}
      <ScrollView style={{ width: '100%' }} contentContainerStyle={{ width: '100%' }}>
        <Text p={3}>Aby zapisać ostatnio oddany stolec wybierz jego typ w tzw. skali Bristolskiej z listy poniżej:</Text>

        {renderStool(1)}
        {renderStool(2)}
        {renderStool(3)}
        {renderStool(4)}
        {renderStool(5)}
        {renderStool(6)}
        {renderStool(7)}
      </ScrollView>
    </View>
  );
};

AddStoolScreen.navigationOptions = (navigationData) => {
  let date = navigationData.navigation.getParam('currentDate');
  date = date ? date.format('DD.MM.YYYY') : '';
  return { headerTitle: `Dodaj stolec (${date})` };
}

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    alignItems: 'center'
  }
});

export default AddStoolScreen;
