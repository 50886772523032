import React, { useState, useEffect, useCallback } from 'react';
import { StyleSheet, ScrollView, TouchableOpacity, ActivityIndicator } from 'react-native';
import { Box, Flex, Spacer, SimpleGrid, Icon, Divider, Text, Center } from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useSelector, useDispatch } from 'react-redux';

import { Calendar } from 'react-native-calendars';
import { HeaderButton, HeaderButtons, Item } from 'react-navigation-header-buttons';

import SurveyTile from '../components/SurveyTile';
import HelpTile from '../components/HelpTile';
import StoolTile from '../components/StoolTile';
import WaterTile from '../components/WaterTile';
import WelcomeModal from '../components/WelcomeModal';

import { changeDate } from '../store/actions/main';
import { loadReports, setCurrentDayReport, addReport, updateGlassCount, addStool, submitSurvey } from '../store/actions/daily-reports';

import Colors from '../theme/Colors';
import DailyReport from '../models/DailyReport';

import moment from 'moment';

const HomeScreen = props => {
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [glassCount, setGlassCount] = useState(0);
  const [stoolCount, setStoolCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const currentDate = useSelector(state => state.main.currentDate);
  const currentDayReport = useSelector(state => state.dailyReports.currentDayReport);
  const surveyResponse = useSelector(state => state.dailyReports.currentDayReport ? state.dailyReports.currentDayReport.surveyResponse : null);
  const reports = useSelector(state => state.dailyReports.reports);

  const currentUserId = useSelector(state => state.main.userId);

  const dispatch = useDispatch();

  const onCurrentDateChange = useCallback(() => {
    let reportForSelectedDate = reports.find(r => r.date.toDateString() === currentDate.toDate().toDateString());
    if (!reportForSelectedDate) {
      reportForSelectedDate = DailyReport.emptyForDate(currentDate.toDate(), currentUserId);
      dispatch(addReport(reportForSelectedDate));
    }
    dispatch(setCurrentDayReport(reportForSelectedDate));
    setGlassCount(reportForSelectedDate.glassCount);
    setStoolCount(reportForSelectedDate.stools.length);
  });

  useEffect(() => {
    if (isLoading) { return; }

    onCurrentDateChange();
  }, [currentDate, isLoading]);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      await dispatch(loadReports(currentUserId));
      setIsLoading(false);
    };

    loadData();
  }, []);

  function renderCalendarBar() {
    return (
      <TouchableOpacity style={{width: '100%'}} onPress={() => setCalendarVisible(!calendarVisible)}>
        <Flex w='100%' direction='row' px={2} py={2} borderBottomColor='#e5e5e5' borderBottomWidth={0.5}>
          <Icon
            as={<MaterialCommunityIcons name="calendar" />}
            size="md"
            mr={4}
            color={Colors.primary}
          />
          <Center>
            <Text color='black'>{currentDate.format('DD.MM.YYYY')}</Text>
          </Center>

          <Spacer />

          <Icon
            as={<MaterialCommunityIcons name={"menu-down"} size={12} />}
            size="md"
            ml={2}
            color={Colors.primary}
          />
        </Flex>
      </TouchableOpacity>
    );
  }

  function markedDates() {
    const result = {};

    reports.forEach((r) => {
      if (r.surveyResponse) {
        result[moment(r.date).format('YYYY-MM-DD')] = { marked: true, dotColor: Colors.primary }
      }
    });

    return result;
  }

  function renderCalendar() {
    if (!calendarVisible) { return; }

    return (
      <Box w='100%'>
        <Calendar
          current={currentDate.format()}
          firstDay={1}
          enableSwipeMonths={true}
          style={{width: '100%'}}
          maxDate={new Date()}
          onDayPress={(day) => {
            dispatch(changeDate(day.dateString));
            setCalendarVisible(false);
          }}
          markedDates={markedDates()}
        />
        <Divider my={2} />
      </Box>
    );
  }

  return (
    isLoading ?
    <ActivityIndicator /> :
    <ScrollView contentContainerStyle={{flexGrow: 1, alignItems: 'center', backgroundColor: 'white'}}>
      <WelcomeModal />

      {renderCalendarBar()}

      {renderCalendar()}

      <SimpleGrid style={styles.grid} columns={2} spacingY={4} spacingX={4}>
        <SurveyTile currentDaySurveyData={surveyResponse} onSubmitSurvey={(surveyData) => {
          dispatch(submitSurvey(currentDayReport, surveyData));
        }} />
        <StoolTile stoolCount={stoolCount} addStool={(bristolType) => {
          dispatch(addStool(currentDayReport, bristolType));
          setStoolCount(stoolCount + 1);
        }} />
        <WaterTile glassCount={glassCount} setGlassCount={(count) => {
          dispatch(updateGlassCount(currentDayReport, count));
          setGlassCount(count);
        }} />
        <HelpTile />
      </SimpleGrid>
    </ScrollView>
  );
};

const MaterialCommunityIconsHeaderButton = (props) => (
  // the `props` here come from <Item ... />
  // you may access them and pass something else to `HeaderButton` if you like
  <HeaderButton IconComponent={MaterialCommunityIcons} iconSize={23} {...props} />
);

HomeScreen.navigationOptions = ({ navigation }) => {
  return {
    headerTitle: 'Dzienniczek Wypróżnień',
    headerRight: () => (
      <HeaderButtons HeaderButtonComponent={MaterialCommunityIconsHeaderButton}>
        <Item title="settings" iconName="cog" color='white' onPress={() => navigation.navigate('Settings')} />
      </HeaderButtons>
    )
  };
}

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  grid: {
    flex: 1,
    paddingTop: 16,
    paddingBottom: 16
  }
});

export default HomeScreen;
