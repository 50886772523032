import React from 'react';
import { useDispatch } from 'react-redux';
import AppLoading from 'expo-app-loading';

import { Platform } from 'react-native';

import firebase from "firebase/app"
import { initFirebase } from '../initialization/firebase';
import "firebase/auth"
import { setUserId } from '../store/actions/main';

const AppLoadingScreen = (props) => {
  const dispatch = useDispatch();

  const loadApp = () => new Promise((resolve, _reject) => {
    initFirebase();

    if (Platform.OS === 'web' && window.location.pathname === '/pomoc') {
      props.navigation.navigate('Help');
      resolve(true);
      return;
    }

    if (Platform.OS === 'web' && window.location.pathname === '/polityka-prywatnosci') {
      props.navigation.navigate('PrivacyPolicy');
      resolve(true);
      return;
    }

    const unsubscribeFn = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log('Logged in with userId', user.uid);

        dispatch(setUserId(user.uid));
      } else {
        console.log('Not logged in');
      }

      unsubscribeFn();

      props.navigation.navigate(!!user ? 'Reports' : 'Auth');
      resolve(true);
    });
  });

  return (
    <AppLoading
      startAsync={loadApp}
      onFinish={() => console.log('onLoadingFinish')}
      onError={(err) => console.log(err)}
    />
  )
}

AppLoadingScreen.navigationOptions = {
  headerShown: false
}

export default AppLoadingScreen;
