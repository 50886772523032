// https://material.io/design/color/the-color-system.html#color-theme-creation
const Colors = {
  primary: '#6200EE', // purple.500
  primaryLight: '#BB86FC', // purple.200
  primaryDark: '#3700B3', // purple.700
  secondary: '#03DAC6', // green-like
  secondaryDark: '#018786',
  background: 'white',
  surface: 'white',
  error: '#B00020',
}

Colors.headerBackgroundColor = Colors.primary;
Colors.headerTintColor = 'white';

export default Colors;
