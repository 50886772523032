import React from 'react';
import { Box, Heading, Text } from 'native-base';
import { useWebNavigateBack } from '../hooks/use-web-navigate-back';
import EmailLink from '../components/EmailLink';

const PrivacyPolicyScreen = props => {
  useWebNavigateBack({
    navigation: props.navigation, routeTitle: 'Polityka Prywatności', routePath: '/polityka-prywatnosci'
  });

  return (
    <Box p="4">
      <Heading size="lg" mb={2}>
        Polityka Prywatności aplikacji "Dzienniczek Wypróżnień"
      </Heading>

      <Heading size="md" mt={4} mb={1}>
        Ogólne informacje
      </Heading>

      <Text mb={2}>
        Podczas tworzenia aplikacji dołożyliśmy wszelkich starań aby zbierane dane były przechowywane w bezpieczny i anonimowy sposób.
      </Text>
      <Text>
        Poniższy dokument przedstawia dokładne informacje o tym jakie dane są zbierane oraz jakie są Państwa prawa z nimi związane.
      </Text>

      <Heading size="md" mt={4} mb={1}>
        Jakie dane są zbierane?
      </Heading>

      <Text mb={2}>
        Jedynymi zbieranymi przez aplikację danymi są uzupełniane przez Państwo dane medyczne dotyczące wypróżnień (codzienne ankiety, informacje o oddanych stolcach).
      </Text>
      <Text>
        Powyższe dane są zbierane anonimowo. Każdy pacjent ma przypisany anonimowy identyfikator pacjenta w formacie <Text italic>xxxxx@dzienniczek-wyproznien.info</Text>, co sprawia, że nie jesteśmy w stanie przypisać konkretnych danych do konkretnego pacjenta.
      </Text>

      <Heading size="md" mt={4} mb={1}>
        W jaki sposób zebrane dane są wykorzystywane?
      </Heading>

      <Text mb={2}>
        Dane są wykorzystywane tylko i wyłącznie w celach naukowych Warszawskiego Uniwersytetu Medycznego i nie są udostępniane stronom trzecim.
      </Text>

      <Heading size="md" mt={4} mb={1}>
        Prawa dotyczące danych
      </Heading>

      <Text mb={2}>
        Dane przechowywane są w sposób anonimowy. Jednak jeśli mimo wszystko wyrażą Państwo chęć ich usunięcia można tego dokonać przesyłając prośbę na adres <EmailLink />, podając identyfikator pacjenta w formacie <Text italic>xxxxx@dzienniczek-wyproznien.info</Text>. Taka prośba będzie wiązała się z końcem udziału w badaniu.
      </Text>
    </Box>
  );
};

PrivacyPolicyScreen.navigationOptions = {
  headerTitle: 'Polityka Prywatności'
}

export default PrivacyPolicyScreen;
